import Bean from './Bean';
import Trip from './Trip';
import Fare from './Fare';
import FareCollection from './FareCollection';
import { Vue, isset } from "@master";

class Itinerary extends Bean {

    set fares(data) {
        if(!isset(this.__data)) {
            this.__data = {};
        }
        this.__data['fares'] = data;
        this.onChange('fares');
    }

    get fares() {
        if(!isset(this.__data)) {
            this.__data = {};
        }
        if(isset(this.__data['fares'])) {
            return this.__data['fares'];
        }
        return null;
    }

    hydrate(data = []) {
        super.hydrate(data);
        this.map('trips', Trip)
            .map('fares', Fare)
            .map('availableFares', Fare);
        Vue.observable(this);
    }

    getPassengerFare(type = null) {
        const property = (type == null) ? 'passengerFare' : `passengerFare${type}`;
        return this.calculate(property, 'fares', () => {
            for(let fare of this.fares) {
                if(type == null || type == fare.travelerType) {
                    return fare;
                }
            }
            return null;
        });
    }

    getIncludedBaggage(segmentNumber) {
        return this.calculate(`segmentNumber${segmentNumber}`, 'fares', () => {
            const baggage = [];
            let prevBaggage = null;
            let includedBaggage = null;
            let isHomogenous = true;
            for(const fare of this.fares) {
                includedBaggage = fare.getIncludedBaggage(segmentNumber);
                if(prevBaggage != null && includedBaggage != prevBaggage) {
                    isHomogenous = false;
                }
                baggage.push({
                    includedBaggage: includedBaggage,
                    travelerType: fare.travelerTypeLabel
                });
                prevBaggage = includedBaggage;
            }
            if(isHomogenous) {
                return [
                    {
                        includedBaggage: baggage[0].includedBaggage,
                        travelerType: 'pax'
                    }
                ];
            } else {
                return baggage;
            }
        });
    }

    getRoutes() {
        const routes = [];
        for(let trip of this.trips) {
            for(let flight of trip.flights) {
                routes.push({
                    segmentNumber: flight.segmentNumber,
                    segment: `${flight.departureAirport.iataCode} - ${flight.arrivalAirport.iataCode}`
                })
            }
        }
        return routes;
    }

    showFareBasisCodes() {
        let hasFareBasisCodes = false;
        if(isset(this.getPassengerFare().flightDetails) && isset(this.getPassengerFare().flightDetails[1]) && isset(this.getPassengerFare().flightDetails[1].fareBasisCode)) {
            hasFareBasisCodes = true;
        }

        let hasBookingClasses = false;
        if(isset(this.getPassengerFare().flightDetails) && isset(this.getPassengerFare().flightDetails[1]) && isset(this.getPassengerFare().flightDetails[1].bookingClass)) {
            hasBookingClasses = true;
        }
        return hasFareBasisCodes && hasBookingClasses
    }

    getFareBasisCodes(segmentNumber) {
        return this.calculate(`fareBasisCodes${segmentNumber}`, 'fares', () => {
            let fareBasisCodes = {};
            for(let fare of this.fares) {
                const fareBasisCode = fare.getFareBasisCode(segmentNumber);
                fareBasisCodes[fare.travelerType] = (fareBasisCode != null) ? fareBasisCode : null;
            }
            if(Object.keys(fareBasisCodes).length > 0) {
                return fareBasisCodes;
            }
            return null;
        });
    }

    getCabinClassesBySegment(segmentNumber) {
        return this.calculate(`cabinClasses${segmentNumber}`, 'fares', () => {
            let cabinClasses = {};
            for(let fare of this.fares) {
                const cabinClass = fare.getCabinClass(segmentNumber);
                cabinClasses[fare.travelerType] = (cabinClass != null) ? cabinClass : null;
            }
            if(Object.keys(cabinClasses).length > 0) {
                return cabinClasses;
            }
            return null;
        });
    }

    getBookingClasses(segmentNumber) {
        return this.calculate(`bookingClasses${segmentNumber}`, 'fares', () => {
            let bookingClasses = {};
            for(let fare of this.fares) {
                const bookingClass = fare.getBookingClass(segmentNumber);
                bookingClasses[fare.travelerType] = (bookingClass != null) ? bookingClass : null;
            }
            if(Object.keys(bookingClasses).length > 0) {
                return bookingClasses;
            }
            return null;
        });
    }

    getFareFamilies(segmentNumber) {
        return this.calculate(`fareFamilies${segmentNumber}`, 'fares', () => {
            let fareFamilies = {};
            for(let fare of this.fares) {
                const fareFamily = fare.getFareFamily(segmentNumber)
                fareFamilies[fare.travelerType] = (fareFamily != null) ? fareFamily : null;
            }
            if(Object.keys(fareFamilies).length > 0) {
                return fareFamilies;
            }
            return null;
        });
    }

    getTicketDesignators(segmentNumber) {
        return this.calculate(`ticketDesignators${segmentNumber}`, 'fares', () => {
            let designators = {};
            for(let fare of this.fares) {
                const designator = fare.getTicketDesignator(segmentNumber)
                designators[fare.travelerType] = (designator != null) ? designator : null;
            }
            if(Object.keys(designators).length > 0) {
                return designators;
            }
            return null;
        });
    }

    getCorporateIds(segmentNumber) {
        return this.calculate(`corporateId${segmentNumber}`, 'fares', () => {
            let corporateIds = {};
            for(let fare of this.fares) {
                const designator = fare.getCorporateId(segmentNumber)
                corporateIds[fare.travelerType] = (designator != null) ? designator : null;
            }
            if(Object.keys(corporateIds).length > 0) {
                return corporateIds;
            }
            return null;
        });
    }

    showCarrierRecordLocator(segmentNumber) {
        return true;
    }

    totalPrice(type='cash', withCommission=true) {
            return this.fares.reduce((total, fare) => {
                let price;
                if(type == 'cash') {
                    price = fare.cashPrice;
                } else {
                    price = fare.price;
                }
                return total += (price + ((fare.collectCommission && withCommission) ? fare.businessCommission : 0)) * fare.numTravelers
            }, 0);
    }

    totalCashDiscount() {
        return this.fares.reduce((total, fare) => {
            return total += fare.cashDiscount * fare.numTravelers
        }, 0);
    }

    totalCommission() {
        return this.fares.reduce((total, fare) => total += fare.businessCommission * fare.numTravelers, 0);
    }

    destinationAirport() {
        let arrivalAirport = '';
        for(let flight of this.trips[0].flights) {
            arrivalAirport = flight.arrivalAirport;
        }
        return arrivalAirport;
    }

    originAirport() {
        return this.trips[0].flights[0].departureAirport;
    }

    getTripOrigin(tripId) {
        return this.calculate(`trips_${tripId}_origin`, 'trips', () => {
            return this.trips[tripId].flights[0].departureAirport;
        });
    }

    getTripDestination(tripId) {
        return this.calculate(`trips_${tripId}_destination`, 'trips', () => {
            return this.trips[tripId].flights[this.trips[tripId].flights.length - 1].arrivalAirport;
        });
    }

    getTripDeparture(tripId) {
        return this.calculate(`trips_${tripId}_departure`, 'trips', () => {
            return this.trips[tripId].flights[0].departureDateFormat;
        });
    }

    getTripArrival(tripId) {
        return this.calculate(`trips_${tripId}_arrival`, 'trips', () => {
            return this.trips[tripId].flights[this.trips[tripId].flights.length - 1].arrivalDateFormat;
        });
    }

    getCommissionsByTravelerType() {
        let commissions = {};
        for(let fare of this.fares) {
            commissions[fare.travelerType] = fare.businessCommission;
        }
        return commissions;
    }

    getFareFamilyKeys() {
        let familyKeys = [];
        for(let fare of this.availableFares) {
            if(!familyKeys.includes(fare.familyKey)) {
                familyKeys.push(fare.familyKey);
            }
        }
        return familyKeys;
    }

    getFaresByFamilyKey(familyKey) {
        let fares = [];
        for(let fare of this.availableFares) {
            if(fare.familyKey == familyKey) {
                fares.push(fare);
            }
        }
        return fares;
    }

    getNumberTravelers() {
        let passengerCount = 0;
        for(const fare of this.fares) {
            passengerCount += fare.numTravelers;
        }
        return passengerCount;
    }

    getFareInformation(information, selectedSegmentNumber, travelerType) {
        let info = null;
        switch (information) {
            case 'cabinClasses':
                info = this.getCabinClassesBySegment(selectedSegmentNumber);
                break;
            case 'fareFamilies':
                info = this.getFareFamilies(selectedSegmentNumber);
                break;
            case 'bookingClasses':
                info = this.getBookingClasses(selectedSegmentNumber);
                break;
            case 'fareBasisCodes':
                info = this.getFareBasisCodes(selectedSegmentNumber);
                break;
            case 'ticketDesignators':
                info = this.getTicketDesignators(selectedSegmentNumber);
                break;
            case 'corporateId':
                info = this.getCorporateIds(selectedSegmentNumber);
                break;
        }
        if(isset(info) && isset(info[travelerType])) {
            return info[travelerType];
        }
        return null;
    }

    cabinClassIsLighter(segmentNumber) {
        return this.getPassengerFare().getHeaviestCabinClass() > this.getPassengerFare().getSegmentCabinClassWeight(segmentNumber);
    }

    getBookingOptions() {
        // Collect fares by Family Key
        let fareCollections = [];
        for(let fare of this.availableFares) {
            if(!isset(fareCollections[fare.familyKey])) {
                fareCollections[fare.familyKey] = new FareCollection({familyKey: fare.familyKey});
            }
            fareCollections[fare.familyKey].addFare(fare);
        }

        let bookingOptions = [];
        for(const fareCollection in fareCollections) {
            bookingOptions.push({
                familyKey: fareCollections[fareCollection].familyKey,
                selected: fareCollections[fareCollection].isSelected(),
                fareFamilies: fareCollections[fareCollection].getFareFamilies().join(' / '),
                averageCashPrice: fareCollections[fareCollection].getAverageCashPrice(),
                averagePrice: fareCollections[fareCollection].getAveragePrice(),
                fares: fareCollections[fareCollection].getFares()
            });
        }

        return bookingOptions;
    }

}

export default Itinerary;
